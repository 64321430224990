import React, {useContext} from "react"
import useAxios from 'axios-hooks'
import {UserContext} from "../auth/UserContextProvider"

import LevelList from '../components/Levels/LevelList'
import "./Home.scss"


export function Home() {
  const {userId, userLoading} = useContext(UserContext)
  const [{ data: levels, loading: levelsLoading, error: levelsError }] = useAxios({url: "levels", headers: {"X-Fields": "{id,name,published_date,created_by{id,username},user_record{completed}}"}})

  return (
    <section className="section">
      <div className="container">
        <h1 className="title">Homepage</h1>
        <div className="columns is-multiline">
          <div className="column is-half">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  Recent Levels
                </p>
              </header>

              { levelsLoading ? (
                <div className="card-content">Loading...</div>
              ) : null}

              { levelsError ? (
                <div className="card-content">Error</div>
              ) : null}

              { !levelsLoading && !levelsError ? (
                <>
                  { levels.length > 0 ? (
                    <LevelList levels={levels} currentUserId={userId} />
                  ) : (
                    <div className="card-content">No levels</div>
                  )}
                </>
              ) : null}

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default Home
