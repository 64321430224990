import axios from "axios"
import React, {useContext, useState, useEffect} from "react"
import { createRoot } from 'react-dom/client'
import {useParams, useNavigate} from 'react-router-dom'
import "./LevelCreate.scss"
import {UserContext} from "../auth/UserContextProvider"
import useAxios from 'axios-hooks'
import apiInstance from '../api/ApiInstance'

const PORTAL_TILE = 30


export function LevelCreate(props) {
  let { levelId } = useParams()
  const {userId, userLoading} = useContext(UserContext)
  const [{ data: userData, loading: userDataLoading, error: userDataError }] = useAxios({
    url: `users/${userId}`,
    headers: {"X-Fields": "{id,username,worlds{id,name}}"}
  }, {manual: !userId})

  const [{ data: levelData, loading: levelDataLoading }] = useAxios(`levels/${levelId}`, {manual: !levelId})
  const [grid, setGrid] = useState([
    [{"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}],
    [{"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}],
    [{"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}],
    [{"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}],
    [{"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}],
    [{"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}],
    [{"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}],
    [{"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}, {"tile": 0}],
  ])



  const [currentTile, setCurrentTile] = useState(0)
  const [createdWorlds, setCreatedWorlds] = useState([])
  const worlds = [...userData?.worlds || [], ...createdWorlds]
  const [portalNumbers, setPortalNumbers] = useState([])
  const [isPublished, setIsPublished] = useState()
  const [showSaved, setShowSaved] = useState(false)
  const [showCreateWorldModal, setShowCreateWorldModal] = useState(false)
  let navigate = useNavigate()

  useEffect(() => {
    if (levelData) {
      const fullGrid = getFullGrid(levelData['data']['grid'])
      setGrid(fullGrid)
      setIsPublished(Boolean(levelData.published_date))
      //refreshPortalNumbers(grid)
    }
  }, [levelData])

  const updateTile = (rowIndex, colIndex) => {
    let newGrid = [...grid]

    let gridItem = {"tile": currentTile}
    if (currentTile == PORTAL_TILE) {
      let nextPortalNumber = getNextPortalNumber()
      gridItem["portalNumber"] = nextPortalNumber
      let newPortalNumbers = [...portalNumbers]
      newPortalNumbers.push(nextPortalNumber)
      setPortalNumbers(newPortalNumbers)
    }

    newGrid[rowIndex][colIndex] = gridItem
    setGrid(newGrid)
    // setPortals(newPortals)
  }

  const getNextPortalNumber = () => {
    if (portalNumbers.length == 0) return 1
    let lastPortalNumber = portalNumbers.slice(-1)[0]
    if (portalNumbers.length % 2 === 0) return lastPortalNumber + 1
    return lastPortalNumber
  }

  const refreshPortalNumbers = (fullGrid) => {
    let newPortalNumbers = []

    for (var row of fullGrid) {
      //console.log(row)
      for (var item of row) {
          //console.log(item)
        if ("portalNumber" in item) {
          newPortalNumbers.push(item["portalNumber"])
        }
      }
    }
    newPortalNumbers.sort((a,b) => a-b)
    setPortalNumbers(newPortalNumbers)
  }

  const deleteRow = (rowIndex) => {
    if (grid.length <= 1) return
    let newGrid = [...grid]
    newGrid.splice(rowIndex, 1)
    setGrid(newGrid)
  }

  const deleteColumn = (colIndex) => {
    if (grid[0].length <= 1) return
    let newGrid = [...grid]
    newGrid.map((elem, index) => { elem.splice(colIndex, 1); return elem; })
    setGrid(newGrid)
  }

  const addPreRow = () => {
    let newGrid = [...grid]
    let newRow = new Array(grid[0].length).fill({"tile": 0})
    newGrid.splice(0, 0, newRow)
    setGrid(newGrid)
  }

  const addPostRow = () => {
    let newGrid = [...grid]
    let newRow = new Array(grid[0].length).fill({"tile": 0})
    newGrid.push(newRow)
    setGrid(newGrid)
  }

  const addPreColumn = () => {
    let newGrid = [...grid]
    newGrid.map((elem, index) => { elem.splice(0, 0, {"tile": 0}); return elem; })
    setGrid(newGrid)
  }

  const addPostColumn = () => {
    let newGrid = [...grid]
    newGrid.map((elem, index) => { elem.push({"tile": 0}); return elem; })
    setGrid(newGrid)
  }

  const getFlatGrid = () => {
    return grid.map((row, index) => {
      return row.map((elem, index) => {
        if ("portalNumber" in elem) {
          return elem["tile"] + "P" + elem["portalNumber"]
        }
        return elem["tile"].toString()
      })
    })
  }

  const getFullGrid = (flatGrid) => {
    return flatGrid.map((flatRow, index) => {
      return flatRow.map((elem, index) => {
        let tileData = String(elem).split("P")
        if (tileData.length > 1) {
          return {"tile": tileData[0], "portalNumber": parseInt(tileData[1])}
        }
        return {"tile": tileData[0]}
      })
    })
  }

  const handleSave = (e) => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    let data = Object.fromEntries(formData)

    data['data'] = {"grid": getFlatGrid()}
    data['user_world_id'] = formData.get("world")
    delete data['world']

    if (levelId) {
      apiInstance.patch(`/levels/${levelId}`, data, {headers: {"X-Fields": "{id,name}"}}).then((response) => {
        setShowSaved(true)
        setTimeout(() => {
          setShowSaved(false)
        }, 3000)
      })
    } else {
      apiInstance.post("/levels", data, {headers: {"X-Fields": "{id,name}"}}).then((response) => {
        setShowSaved(true)
        setTimeout(() => {
          setShowSaved(false)
        }, 3000)
        navigate(getLevelEditUrl(response.data['id']))
      })
    }
  }

  const handleWorldSave = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)

    apiInstance.post("/worlds", Object.fromEntries(formData), {headers: {"X-Fields": "{id,name}"}}).then((response) => {
      setCreatedWorlds(createdWorlds => [...createdWorlds, {"id": response.data["id"], "name": response.data["name"]}])
      setShowCreateWorldModal(false)
    })
  }

  const publishLevel = () => {
    apiInstance.patch(`/levels/${levelId}`, {"published": true}, {headers: {"X-Fields": "{id,published_date}"}}).then((response) => {
        setIsPublished(Boolean(response.data['published_date']))
    })
  }

  const unpublishLevel = () => {
    apiInstance.patch(`/levels/${levelId}`, {"published": false}, {headers: {"X-Fields": "{id,published_date}"}}).then((response) => {
        setIsPublished(Boolean(response.data['published_date']))
    })
  }

  const getLevelUrl = (id) => {
    return "/levels/" + id + "/"
  }

  const getLevelEditUrl = (id) => {
    return "/levels/" + id + "/edit/"
  }

  if (userDataLoading || levelDataLoading) return <p>Loading...</p>

  return (
    <section className="section">
      <div className="container">
        <h1 className="title">{ levelId ? levelData?.name : "Create Level"}
          { levelId && isPublished && (
            <span className="tag is-success ml-2">Published</span>
          )}
          { levelId && !isPublished && (
            <span className="tag is-danger is-light ml-2">Unpublished</span>
          )}
        </h1>

        {
          levelId ? <a className="button is-link" href={getLevelUrl(levelId)}>Play Level</a> : null
        }

        <div className="columns">
          <div className="column is-half">
            <form method="post" onSubmit={handleSave}>

              <div className="field">
                <label className="label" htmlFor="name">Name</label>
                <p className="control">
                  <input id="name" className="input" name="name" size="5" defaultValue={levelData && levelData['name']} required />
                </p>
              </div>

              <div className="field">
                <label className="label" htmlFor="world">World</label>
                { worlds?.length ? (
                  <div className="select mr-2">
                    <select id="world" name="world">
                      {worlds.map((world, index) => (
                        <option value={world.id} key={world.id}>{world.name}</option>
                      ))}
                    </select>
                  </div>
                ) : null}

                <span className="button is-light" onClick={(e) => {e.preventDefault(); setShowCreateWorldModal(true)}}>Create World</span>
              </div>


              <div className="field">
                <div className="control">
                  <button className="button is-info">Save</button>

                    { levelId && isPublished && (
                      <button className="button is-danger is-light ml-2" onClick={(e) => {e.preventDefault(); unpublishLevel()}}>Unpublish</button>
                    )}

                    { levelId && !isPublished && (
                      <button className="button is-primary ml-2" onClick={(e) => {e.preventDefault(); publishLevel()}}>Publish</button>
                    )}

                </div>
              </div>

              {showSaved && <p>Saved</p>}

            </form>
          </div>
        </div>

        <table className="table level-grid">
          <tbody>
          <tr>
            <td rowSpan="100" onClick={() => addPreColumn()}>+</td>
            <td className="has-text-centered" colSpan="100" onClick={() => addPreRow()}>+</td>
            <td rowSpan="100" onClick={() => addPostColumn()}>+</td>
          </tr>
          <tr>
            <td></td>
            {[...Array(grid[0].length)].map((x, i) =>
              <td key={`delete-col-start-${i}`} className="has-text-centered" onClick={() => deleteColumn(i)}>-</td>
            )}
            <td></td>
          </tr>
          {
            grid.map((row, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  <td key={`delete-row-start-${rowIndex}`} onClick={() => deleteRow(rowIndex)}>-</td>
                  {row.map((value, colIndex) => (
                    <td key={`${rowIndex}-${colIndex}`} className={`game-tile game-tile-${value['tile']}`} onClick={() => updateTile(rowIndex, colIndex)}></td>
                  ))}
                  <td key={`delete-row-end-${rowIndex}`} onClick={() => deleteRow(rowIndex)}>-</td>
                </tr>
              )
            })
          }
          <tr>
            <td></td>
            {[...Array(grid[0].length)].map((x, i) =>
              <td key={`delete-col-end-${i}`} className="has-text-centered" onClick={() => deleteColumn(i)}>-</td>
            )}
            <td></td>
          </tr>
          <tr><td  className="has-text-centered" colSpan="100" onClick={() => addPostRow()}>+</td></tr>
          </tbody>

        </table>

        <div>
          <p>Select a tile</p>
          <h3>Blocks</h3>
          <ul className="block-list">
            <li className="game-tile game-tile-0" onClick={() => setCurrentTile(0)} />
            <li className="game-tile game-tile-1" onClick={() => setCurrentTile(1)} />
            <li className="game-tile game-tile-2" onClick={() => setCurrentTile(2)} />
          </ul>

          <h3>Mirrors</h3>
          <ul className="block-list">
            <li className="game-tile game-tile-10" onClick={() => setCurrentTile(10)} />
            <li className="game-tile game-tile-11" onClick={() => setCurrentTile(11)} />
            <li className="game-tile game-tile-12" onClick={() => setCurrentTile(12)} />
            <li className="game-tile game-tile-13" onClick={() => setCurrentTile(13)} />
          </ul>

          <h3>Turrets</h3>
          <ul className="block-list">
            <li className="game-tile game-tile-20" onClick={() => setCurrentTile(20)} />
            <li className="game-tile game-tile-21" onClick={() => setCurrentTile(21)} />
            <li className="game-tile game-tile-22" onClick={() => setCurrentTile(22)} />
            <li className="game-tile game-tile-23" onClick={() => setCurrentTile(23)} />
          </ul>

          <h3>Portal</h3>
          <ul className="block-list">
            <li className="game-tile game-tile-30" onClick={() => setCurrentTile(30)} />
          </ul>

          <h3>Player / Exit</h3>
          <ul className="block-list">
            <li className="game-tile game-tile-3" onClick={() => setCurrentTile(3)} />
            <li className="game-tile game-tile-4" onClick={() => setCurrentTile(4)} />
          </ul>
        </div>

        <div className={showCreateWorldModal ? "modal is-active" : "modal"}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Create World</p>
              <button className="delete" aria-label="close"></button>
            </header>
            <section className="modal-card-body">
              <form method="post" onSubmit={handleWorldSave}>
                <div className="field">
                  <label className="label" htmlFor="world_name">Name</label>
                  <p className="control">
                    <input id="world_name" className="input" name="name" required />
                  </p>
                </div>
                <button className="button is-link">Save changes</button>
                <button className="button" onClick={() => setShowCreateWorldModal(false)}>Cancel</button>
              </form>
            </section>

          </div>
        </div>
      </div>
    </section>
  )
}


export default LevelCreate
