import React, {useState} from "react"
import { Auth } from 'aws-amplify'
import { useNavigate } from "react-router-dom"

import {UNCONFIRMED_USER_KEY} from '../utils/Constants'


export function Register() {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [cognitoError, setCognitoError] = useState()
  let navigate = useNavigate()

  const onSubmit = (e) => {
    e.preventDefault()
    Auth.signUp({
      username: username,
      password: password,
      attributes: {
        email: email,
      },
      autoSignIn: {enabled: true}
    }).then(() => {
      localStorage.setItem(UNCONFIRMED_USER_KEY, username)
      navigate("/user/confirmation")
    }).catch(error => {
      setCognitoError(error.message)
    })
  }

  return (
    <section className="section">
      <div className="container">
        <h1 className="title">Register</h1>
        <form method="post" onSubmit={onSubmit}>

            <div className="field">
              <label className="label" htmlFor="username">Username</label>
              <p className="control has-icons-left has-icons-right">
                <input id="username" className="input" name="username" type="text" onChange={(e) => setUsername(e.target.value)} />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
              </p>
            </div>

            <div className="field">
              <label className="label" htmlFor="email">Email</label>
              <p className="control has-icons-left has-icons-right">
                <input id="email" className="input" name="password" type="email" onChange={(e) => setEmail(e.target.value)} />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>

            </div>

            <div className="field">
              <label className="label" htmlFor="password">Password</label>
              <p className="control has-icons-left">
                <input id="password" className="input" name="password" type="password" onChange={(e) => setPassword(e.target.value)} />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>

            { cognitoError ? (
                <p className="help is-danger">{ cognitoError }</p>
            ) : null }

            <div className="field">
              <div className="control">
                <button className="button is-link">Register</button>
              </div>
            </div>
        </form>
      </div>
    </section>
  )
}


export default Register
