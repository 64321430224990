import { Direction } from '../consts/Direction'

const TILE_SIZE = 64
const HALF_TILE_SIZE = TILE_SIZE / 2

const BOX = 5
const EXIT = 34
const PLAYER = 41
const PORTAL = 25
const WALL = 3
const TURRET_UP = 11
const TURRET_RIGHT = 12
const TURRET_DOWN = 13
const TURRET_LEFT = 14
const MIRROR_UP_LEFT = 21
const MIRROR_UP_RIGHT = 22
const MIRROR_DOWN_RIGHT = 23
const MIRROR_DOWN_LEFT = 24

const BOX_DEPTH = 0
const MIRROR_DEPTH = -2
const LASER_DEPTH = -1
const PORTAL_DEPTH = -5


const createSpritesByGID = (game, gid, props, depth = 0) => {
    return game.layer.createFromTiles(gid, 0, { key: 'tiles', frame: gid-1 }).map(tile => {
        tile.setOrigin(0)
        tile.setDepth(depth)
        Object.assign(tile, props)
        return tile
    })
}


const createBoxes = (game) => {
    game.boxes = createSpritesByGID(game, BOX)
}

const createPlayer = (game) => {
    game.player = createSpritesByGID(game, PLAYER).pop()
}


const createPortals = (game) => {
    game.portals = createSpritesByGID(game, PORTAL, {}, PORTAL_DEPTH)
    for (var portalPair of game.portalPairs) {
        game.portals[portalPair[0]].nextPortal = game.portals[portalPair[1]]
        game.portals[portalPair[1]].nextPortal = game.portals[portalPair[0]]
    }
}


const createMirrors = (game) => {
    game.mirrors = [].concat(
        ...createSpritesByGID(game, MIRROR_UP_LEFT, {"openDirections": [Direction.RIGHT, Direction.DOWN]}, MIRROR_DEPTH),
        ...createSpritesByGID(game, MIRROR_UP_RIGHT, {"openDirections": [Direction.LEFT, Direction.DOWN]}, MIRROR_DEPTH),
        ...createSpritesByGID(game, MIRROR_DOWN_RIGHT, {"openDirections": [Direction.LEFT, Direction.UP]}, MIRROR_DEPTH),
        ...createSpritesByGID(game, MIRROR_DOWN_LEFT, {"openDirections": [Direction.RIGHT, Direction.UP]}, MIRROR_DEPTH),
    )
}


const createTurrets = (game) => {
    game.turrets = [].concat(
        ...createSpritesByGID(game, TURRET_UP, {"direction": Direction.UP}),
        ...createSpritesByGID(game, TURRET_RIGHT, {"direction": Direction.RIGHT}),
        ...createSpritesByGID(game, TURRET_DOWN, {"direction": Direction.DOWN}),
        ...createSpritesByGID(game, TURRET_LEFT, {"direction": Direction.LEFT}),
    )
}


const createSprites = (game) => {
    createBoxes(game)
    createPortals(game)
    createTurrets(game)
    createMirrors(game)
    createPlayer(game)
}


export {
    createSpritesByGID,
    createTurrets,
    createSprites,
    BOX_DEPTH,
    MIRROR_DEPTH,
    LASER_DEPTH,
    EXIT,
    WALL
}
