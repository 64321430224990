import React, {useState} from "react"
import { Auth } from 'aws-amplify'
import {UNCONFIRMED_USER_KEY} from '../utils/Constants'
import { useNavigate } from "react-router-dom"


export function LogIn() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [cognitoError, setCognitoError] = useState()
  let navigate = useNavigate()

  const onSubmit = (e) => {
    e.preventDefault()

    Auth.signIn(username, password).then(user => {
      localStorage.removeItem(UNCONFIRMED_USER_KEY)
      window.location.href = "/"
    }).catch(error => {
      if (error.name === "UserNotConfirmedException"){
        localStorage.setItem(UNCONFIRMED_USER_KEY, username)
        navigate("/user/confirmation")
      }
      setCognitoError(error.message)
    })

  }

  return (
    <section className="section">
      <div className="container">
        <h1 className="title">Log In</h1>
        <form method="post" onSubmit={onSubmit}>

            <div className="field">
              <label className="label" htmlFor="username">Username</label>
              <p className="control has-icons-left has-icons-right">
                <input id="username" className="input" name="username" onChange={(e) => setUsername(e.target.value)} />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
              </p>
            </div>

            <div className="field">
              <label className="label" htmlFor="password">Password</label>
              <p className="control has-icons-left">
                <input id="password" className="input" name="password" type="password" onChange={(e) => setPassword(e.target.value)} />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>

            { cognitoError ? (
                <p className="help is-danger">{ cognitoError }</p>
            ) : null }

            <div className="field">
              <div className="control">
                <button className="button is-link">Login</button>
              </div>
            </div>
        </form>
      </div>
    </section>
  )
}


export default LogIn
