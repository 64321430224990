import { Direction } from "../consts/Direction"

interface IBaseTween
{
	x?: number | string
	y?: number | string
	duration?: number
}

const baseTweenForDirection = (direction: Direction) => {
	const baseTween: IBaseTween = {
		duration: 200
	}

	switch (direction)
	{
		case Direction.DOWN:
			baseTween.y = '+=64'
			break

		case Direction.UP:
			baseTween.y = '-=64'
			break

		case Direction.LEFT:
			baseTween.x = '-=64'
			break

		case Direction.RIGHT:
			baseTween.x = '+=64'
			break
	}

	return baseTween
}

export {
	baseTweenForDirection
}
