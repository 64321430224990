import React, {useContext, useEffect, useState} from "react"
import useAxios from 'axios-hooks'
import {useParams} from 'react-router-dom'
import apiInstance from '../api/ApiInstance'
import {UserContext} from "../auth/UserContextProvider"

import LevelList from '../components/Levels/LevelList'


export function UserDetail() {
  let { userId } = useParams()
  const {userId: currentUserId} = useContext(UserContext)
  const [{ data: userData, loading: userLoading, error: userError }] = useAxios({
    url: `users/${userId}`,
    headers: {
      "X-Fields": "{id,username,worlds{id,name,levels{id,name,published_date,user_record{completed}}}}"
    }
  })

  const [worldNames, setWorldNames] = useState({})
  const [user, setUser] = useState(null)

  useEffect(() => {
    setUser(userData)
  }, [userData])

  const editWorld = (id, name) => {
    setWorldNames(Object.assign({[id]: name}, worldNames))
  }

  const cancelEditWorld = (id) => {
    let {[id]: _, ...newWorldNames} = worldNames
    setWorldNames(newWorldNames)
  }

  const updateWorldName = (id, name) => {
    setWorldNames({ ...worldNames, [id]: name })
  }

  const saveWorldName = (id) => {
    apiInstance.patch(`/worlds/${id}`, {"name": worldNames[id]}).then((response) => {
      console.log("success")
    })
    user.worlds = user.worlds.map(world => (world.id === id ? { ...world, name: worldNames[id] } : world))
    setUser(user)
    cancelEditWorld(id)
  }

  const deleteWorld = (id) => {
    apiInstance.delete(`/worlds/${id}`).then((response) => {
      setUser({...user, "worlds": user.worlds.filter(world => world.id !== id)})
    })
  }

  if (userLoading) return <div className=""><p>Loading...</p></div>
  if (userError) return <div className=""><p>Error</p></div>
  if (!user) return <div className=""><p>...</p></div>

  return (
    <section className="section">
      <div className="container">
        <h1 className="title">{user.username}</h1>
        <div className="columns is-multiline">
          {user.worlds.map((world) => {
            return (
              <div className="column is-half" key={world.id}>
                <div className="card">
                  <header className="card-header">
                    <p className="card-header-title">
                      { world.id in worldNames ? (
                        <>
                          <input className="input" defaultValue={world.name} required onChange={(e) => updateWorldName(world.id, e.target.value)} />
                          <span className="button is-primary ml-3" onClick={() => saveWorldName(world.id)}>Save</span>
                          <span className="button is-light ml-3" onClick={() => cancelEditWorld(world.id)}>Cancel</span>
                        </>
                      ) : (
                        <>
                          {world.name}
                          { user.id == currentUserId && (
                            <>
                              <span className="button is-light ml-3" onClick={() => editWorld(world.id, world.name)}>Edit</span>
                              { world.levels.length == 0 && (
                                <span className="button is-danger ml-3" onClick={() => deleteWorld(world.id)}>Delete</span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </p>
                  </header>

                  { world.levels.length > 0 ? (
                    <LevelList levels={world.levels} currentUserId={currentUserId} createdBy={{"id": user.id, "username": user.username}} />
                  ) : (
                    <div className="card-content">No levels</div>
                  )}

                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}


export default UserDetail
