import "./Navigation.scss"

import React, {useContext} from "react"
import {UserContext} from "../../auth/UserContextProvider"

import { Auth } from 'aws-amplify'


export function Navigation() {
  const {user, userLoading} = useContext(UserContext)

  async function signOut() {
    try {
      await Auth.signOut()
      window.location.reload(false)
    } catch (error) {
      console.log("Error signing out: ", error)
    }
  }

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src='/img/logo.svg' width="112" height="28" />
        </a>

        <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className="navbar-menu">
        <div className="navbar-start">
          <a href="/" className="navbar-item">
            Home
          </a>

          <div className="navbar-item">
          { user ? (
            <a href="/levels/create" className="button is-primary">
              <strong>Create Level</strong>
            </a>
          ) : null }
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">

              { user ? (
                <span href="" className="button is-light" onClick={() => {signOut()}}>
                  Log out
                </span>
              ) : (
                <>
                  <a href="/register" className="button is-primary">
                    <strong>Sign up</strong>
                  </a>
                  <a href="/login" className="button is-light">
                    Log in
                  </a>
                </>
              )}

            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
