import Phaser from 'phaser'

import WebFontFile from '../files/WebFontFile'

export default class Preloader extends Phaser.Scene
{
	constructor()
	{
		super('preloader')
	}

	preload()
	{
		const fonts = new WebFontFile(this.load, [
			'Poppins',
			'Righteous'
		])
		this.load.addFile(fonts)

		this.load.audio('game-music', '/audio/music/CaveLoop.wav')
		this.load.audio('confirmation', '/audio/sfx/confirmation_001.wav')
		this.load.audio('move', '/audio/sfx/maximize_008.wav')
		this.load.audio('error', '/audio/sfx/error_006.wav')
		this.load.audio('click', '/audio/sfx/click2.wav')
	}

	create()
	{
		const level = this.game['levelData']
		const levelData = {
            "id": level.id,
            "width": level.data['grid'].length,
            "height": level.data['grid'][0].length,
            "portal_pairs": level['data']['portal_pairs'],
            "user_record": level['user_record']
        }
		this.scene.start('game', { levelData: levelData })
	}
}
