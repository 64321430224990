import React, {useEffect, useState} from "react"
import { Auth } from 'aws-amplify'


export const UserContext = React.createContext()


export function UserContextProvider(props) {
  const [user, setUser] = useState(null)
  const [userId, setUserId] = useState(null)
  const [userLoading, setUserLoading] = useState(true)
  const [userError, setUserError] = useState(true)

  const getCurrentUser = () => {
    Auth.currentAuthenticatedUser().then(user => {
        setUserId(user.attributes.sub)
        setUser({
          "id": user.attributes.sub
        })
        setUserLoading(false)
    }).catch(error => {
        console.log("error", error)
        setUserError(error)
        setUserLoading(false)
    })
  }

  useEffect(() => {
    getCurrentUser()
  }, [])

  let value = {
    user: user,
    userId: userId,
    userLoading: userLoading,
    userError: userError,
    setUser: setUser
  }

  return (
    <UserContext.Provider value={value}>
        {props.children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
