import Phaser from 'phaser'

import * as Colors from '../consts/Color'
import { Direction, Move } from '../consts/Direction'

import {
	boxColorToTargeColor
} from '../utils/ColorUtils'

import { offsetForDirection, oppositeDirection } from '../utils/DirectionUtils'
import { HALF_TILE_SIZE, TILE_SIZE, TileManager } from '../utils/TileUtils'
import { baseTweenForDirection } from '../utils/TweenUtils'
import { createSprites, BOX_DEPTH, LASER_DEPTH, MIRROR_DEPTH, WALL, EXIT } from '../utils/SpriteUtils'
import apiInstance from '../../../api/ApiInstance'

const Rectangle = Phaser.Geom.Rectangle
const GetRectangleIntersection = Phaser.Geom.Intersects.RectangleToRectangle
const LineToRectangle = Phaser.Geom.Intersects.LineToRectangle


export default class Game extends Phaser.Scene
{
	private player?: Phaser.GameObjects.Sprite
	private layer?: Phaser.Tilemaps.TilemapLayer
	private backgroundLayer?: Phaser.Tilemaps.TilemapLayer

	private movesCountLabel?: Phaser.GameObjects.Text

	private cursors?: Phaser.Types.Input.Keyboard.CursorKeys

	private movesCount = 0
	private moveHistory: Array<any>
	private currentLevel = String
	private height = 4
	private width = 4
	private isLevelComplete = false
	private isDead = false
	private hasAttempted = false
	private tileManager
	private boxes: Array<Phaser.GameObjects.Sprite>
	private lasers: Array<any>
	private mirrors: Array<any>
	private portals: Array<any>
	private turrets: Array<any>
	private portalPairs: Array<any>

	constructor()
	{
		super('game')
		this.tileManager = new TileManager(this)
	}

	init(d: { levelData })
	{
		const data =  d

		this.currentLevel = data.levelData.id
		this.width = data.levelData.width
		this.height = data.levelData.height
		this.portalPairs = data.levelData.portal_pairs
		this.hasAttempted = Boolean(data.levelData.user_record)
		this.movesCount = 0
		this.moveHistory = []

		this.isLevelComplete = false
	}

	preload()
	{
		this.load.tilemapTiledJSON('tilemap', `${process.env.REACT_APP_API_URL}levels/${this.currentLevel}/data`)
		this.load.spritesheet('tiles', '/img/tilesheet.png', {
			frameWidth: 64,
			startFrame: 0
		})

		this.cursors = this.input.keyboard.createCursorKeys()
		this.boxes = []
		this.lasers = []
		this.mirrors = []
		this.portals = []
		this.turrets = []
	}

	create(d: { level: number })
	{
		var background = this.add.tileSprite(0, 0, 512, 512, "tiles", 31)
		background.setDepth(-100)
		background.setOrigin(0, 0)

		const map = this.make.tilemap({ key: 'tilemap' })

		const tiles = map.addTilesetImage('sokoban', 'tiles')
		this.layer = map.createLayer('Level', tiles, 0, 0)

		createSprites(this)

		this.createPlayerAnims()

		this.drawLasers()

		this.input.keyboard.on('keydown-BACKSPACE', this.undoMove, this);

		this.events.on(Phaser.Scenes.Events.SHUTDOWN, () => {
			this.cache.tilemap.remove('tilemap')
		})
	}

	update()
	{
		if (!this.cursors || !this.player)
		{
			return
		}

		const justLeft = Phaser.Input.Keyboard.JustDown(this.cursors.left!)
		const justRight = Phaser.Input.Keyboard.JustDown(this.cursors.right!)
		const justDown = Phaser.Input.Keyboard.JustDown(this.cursors.down!)
		const justUp = Phaser.Input.Keyboard.JustDown(this.cursors.up!)

		if (justLeft) {
			this.move(Direction.LEFT)
		} else if (justRight) {
			this.move(Direction.RIGHT)
		} else if (justUp) {
			this.move(Direction.UP)
		} else if (justDown) {
			this.move(Direction.DOWN)
		}
	}

	private move(direction: Direction)
	{
		if (!this.hasAttempted) {
			this.hasAttempted = true
			apiInstance.post('levels/' + this.currentLevel + '/user-record').then((response) => {
				console.log("level started", response)
			})
		}

		if (!this.player || this.tweens.isTweening(this.player!) || this.isDead) {
			return
		}

		const x1 = this.player.x
		const y1 = this.player.y

		const offset = offsetForDirection(direction)

		const x2 = x1 + offset.x
		const y2 = y1 + offset.y

		if (!this.tileManager.canPlayerMoveTo(x2, y2)) {
			this.sound.play('error')
			return
		}

		let box = this.tileManager.getMoveableAt(x2, y2)

		if (!box) {
			const portal = this.tileManager.getPortalAt(x2, y2)
			box = this.tileManager.getMoveableAt(portal?.nextPortal.x, portal?.nextPortal.y)
		}

		const baseTween = baseTweenForDirection(direction)

		if (box) {
			const x3 = x2 + offset.x
			const y3 = y2 + offset.y

			if (!this.tileManager.canBoxMoveTo(x3, y3)) {
				this.sound.play('error')
				return
			}

			this.sound.play('move')

			this.tweens.add(Object.assign(
				baseTween,
				{
					targets: box,
					onComplete: () => {
						this.teleport(box, x3, y3)
						this.updateLasers()
					}
				}
			))
		}

		const playerAnimation = this.getPlayerAnimation(direction)

		this.tweens.add(Object.assign(
			baseTween,
			{
				targets: this.player,
				onComplete: () => {
					this.moveHistory.push({"direction": direction, "withBox": box ? true : false})
					if (this.tileManager.hasExitAt(x2, y2, EXIT)) {
						this.completeLevel()
					}
					this.handlePlayerStopped(x2, y2) //.bind(this)
					this.checkIfDead(x2, y2)
					// this.teleport(ox, oy)
				},
				onCompleteScope: this,
				onStart: playerAnimation
			}
		))
	}

	private getPlayerAnimation(direction: Direction) {
		switch (direction) {
			case Direction.LEFT:
				return () => {this.player?.anims.play('left', true)}
			case Direction.RIGHT:
				return () => {this.player?.anims.play('right', true)}
			case Direction.UP:
				return () => {this.player?.anims.play('up', true)}
			case Direction.DOWN:
				return () => {this.player?.anims.play('down', true)}
		}
	}

	private completeLevel() {
		this.isLevelComplete = true
		const userRecordData = JSON.stringify({"moves": this.movesAsString()})

		apiInstance.patch('levels/' + this.currentLevel + '/user-record', userRecordData).then((response) => {
			console.log("level complete saved", response)
		})
	}

	private undoMove() {
		if (!this.player || this.tweens.isTweening(this.player!) || this.moveHistory.length == 0) {
			return
		}

		const move = this.moveHistory.slice(-1)[0]
		const undoDirection = oppositeDirection(move.direction)

		const x1 = this.player.x
		const y1 = this.player.y

		const offset = offsetForDirection(move.direction)

		const x0 = x1 - offset.x
		const y0 = y1 - offset.y
		const x2 = x1 + offset.x
		const y2 = y1 + offset.y

		let box = this.tileManager.getMoveableAt(x2, y2)

		const baseTween = baseTweenForDirection(undoDirection)

		if (move.withBox && box) {
			this.sound.play('move')

			this.tweens.add(Object.assign(
				baseTween,
				{
					targets: box,
					onComplete: () => {
						// this.teleport(box, nx, ny)
						this.updateLasers()

					}
				}
			))
		}

		this.tweens.add(Object.assign(
			baseTween,
			{
				targets: this.player,
				onComplete: () => {
					this.moveHistory.pop()
					this.handlePlayerStopped(x0, y0)
					this.checkIfDead(x0, y0)
				},
				onCompleteScope: this,
				onStart: this.getPlayerAnimation(move.direction)
			}
		))
	}

	private movesAsString() {
		var moveMap = {
			[Direction.UP]: "U",
			[Direction.RIGHT]: "R",
			[Direction.DOWN]: "D",
			[Direction.LEFT]: "L"
		}
		var movesString = this.moveHistory.map((move, index) => moveMap[move.direction]).join("")
		return movesString
	}

	private handlePlayerStopped(x, y)
	{
		this.movesCount++
		this.stopPlayerAnimation()
		this.teleport(this.player, x, y)

		this.updateMovesCount()

		if (this.isLevelComplete)
		{
			this.scene.start('level-finished', {
				moves: this.movesCount,
				currentLevel: this.currentLevel
			})
		}
	}

	private checkIfDead(x, y) {
		if (this.tileManager.hasLaserAt(x, y)) {
			this.isDead = true
			this.player?.anims.play('death', true)
		} else {
			this.isDead = false
		}
	}

	private teleport(sprite, x, y) {
		const portal = this.tileManager.getPortalAt(x, y)
		if (portal?.nextPortal) {
			this.tweens.chain({
				targets: sprite,
				tweens: [
					{
						ease: 'Sine.easeInOut',
						duration: 100,
						delay: 0,
						alpha: {
						getStart: () => 1,
						getEnd: () => 0.0
						},
					},
					{
						duration: 0,
						x: {
						getStart: () => sprite.x,
						getEnd: () => portal.nextPortal.x
						},
						y: {
						getStart: () => sprite.y,
						getEnd: () => portal.nextPortal.y
						},
					},
					{
						ease: 'Sine.easeInOut',
						duration: 100,
						delay: 0,
						alpha: {
						getStart: () => 0,
						getEnd: () => 1
						},
					},
				]
			})
		}
	}

	private getMirrorDirection(mirror, incomingDirection) {
		const openDirections = mirror.openDirections
		if (openDirections.includes(incomingDirection)) {
			return oppositeDirection(openDirections.filter(item => item !== incomingDirection)[0])
		}
	}

	private getNextSquare(square, direction) {
		const offset = offsetForDirection(direction)
		return {"x": square.x + offset.x, "y": square.y + offset.y}
	}

	private getLaserEnd(square, direction) {
		let squareToCheck = square
		// TODO: i < LEVEL_WIDTH - square.x etc
		for (let i = 0; i < 10; i++) {
			squareToCheck = this.getNextSquare(squareToCheck, direction)
			if (this.tileManager.hasWallAt(squareToCheck.x, squareToCheck.y) || this.tileManager.hasBoxAt(squareToCheck.x, squareToCheck.y)) {
				return squareToCheck
			}
			let mirror = this.tileManager.getMirrorAt(squareToCheck.x, squareToCheck.y)
			if (mirror !== undefined) {
				if (mirror.openDirections.includes(direction)) {
					mirror.setDepth(MIRROR_DEPTH)
					let newDirection = this.getMirrorDirection(mirror, direction)
					this.drawLaserSegment(squareToCheck, newDirection)
				} else {
					mirror.setDepth(BOX_DEPTH)
				}
				return squareToCheck
			}
			let portal = this.tileManager.getPortalAt(squareToCheck.x, squareToCheck.y)
			if (portal !== undefined) {
				this.drawLaserSegment(portal.nextPortal, direction)
				return squareToCheck
			}
		}
		return squareToCheck
	}

	private drawLaserSegment(square, direction) {
		let laserEndSquare = this.getLaserEnd(square, direction)
		let laser = this.add.line(
	        0, 0, square.x+HALF_TILE_SIZE, square.y+HALF_TILE_SIZE, laserEndSquare.x+HALF_TILE_SIZE, laserEndSquare.y+HALF_TILE_SIZE, 0x00ff00
	    ).setOrigin(0)
	    // @ts-ignore.
	    laser.plane = "horizontal"
	    // @ts-ignore.
	    laser.direction = direction
	    laser.setDepth(LASER_DEPTH)
	    this.lasers.push(laser)
	}

	private drawLasers() {
		for (const turret of this.turrets) {
			this.drawLaserSegment({"x": turret.x, "y": turret.y}, turret.direction)
		}
	}

	private updateLasers() {
		for (const laser of this.lasers) {
			laser.destroy()
		}
		this.lasers.length = 0
		this.drawLasers()
	}

	private updateMovesCount()
	{
		if (!this.movesCountLabel)
		{
			return
		}
		this.movesCountLabel.text = `Moves: ${this.movesCount}`
	}

	private stopPlayerAnimation()
	{
		if (!this.player)
		{
			return
		}

		const key = this.player?.anims.currentAnim?.key
		if (!key.startsWith('idle-'))
		{
			this.player.anims.play(`idle-${key}`, true)
		}
	}

	private createPlayerAnims()
	{
		this.anims.create({
			key: 'idle-down',
			frames: [ { key: 'tiles', frame: 41 }]
		})

		this.anims.create({
			key: 'idle-left',
			frames: [ { key: 'tiles', frame: 64 }]
		})

		this.anims.create({
			key: 'idle-right',
			frames: [ { key: 'tiles', frame: 61 }]
		})

		this.anims.create({
			key: 'idle-up',
			frames: [ { key: 'tiles', frame: 44 }]
		})

		this.anims.create({
			key: 'idle-death',
			frames: [ { key: 'tiles', frame: 34 }]
		})

		this.anims.create({
			key: 'left',
			frames: this.anims.generateFrameNumbers('tiles', { start: 63, end: 65 }),
			frameRate: 10,
			repeat: -1
		})

		this.anims.create({
			key: 'right',
			frames: this.anims.generateFrameNumbers('tiles', { start: 60, end: 62 }),
			frameRate: 10,
			repeat: -1
		})

		this.anims.create({
			key: 'up',
			frames: this.anims.generateFrameNumbers('tiles', { start: 43, end: 45 }),
			frameRate: 10,
			repeat: -1
		})

		this.anims.create({
			key: 'down',
			frames: this.anims.generateFrameNumbers('tiles', { start: 40, end: 42 }),
			frameRate: 10,
			repeat: -1
		})

		this.anims.create({
			key: 'death',
			frames: [ { key: 'tiles', frame: 34 }]
		})
	}
}
