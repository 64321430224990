import Phaser from 'phaser'


class Button {
    constructor(x, y, label, scene, callback) {
        const button = scene.add.text(x, y, label)
            .setOrigin(0.5)
            .setPadding(10)
            .setStyle({ backgroundColor: '#111' })
            .setInteractive({ useHandCursor: true })
            .on('pointerdown', () => callback())
            .on('pointerover', () => button.setStyle({ fill: '#f39c12' }))
            .on('pointerout', () => button.setStyle({ fill: '#FFF' }));
    }
}


export default class LevelFinishedScene extends Phaser.Scene
{
	constructor()
	{
		super('level-finished')
	}

	create(d: { moves: number, currentLevel: number })
	{
		const data = Object.assign({ moves: 0, currentLevel: "3c30959f-7601-44b6-847c-34450aed77a9" }, d)
		const width = this.scale.width
		const height = this.scale.height
		this.add.text(width * 0.5, height * 0.4, 'Level Complete!', {
			fontFamily: 'Righteous',
			color: '#d4fa00',
			fontSize: 48
		})
		.setOrigin(0.5)

		this.add.text(width * 0.5, height * 0.5, `Moves: ${data.moves}`, {
			fontFamily: 'Poppins'
		})
		.setOrigin(0.5)


		const retryButton = new Button(width * 0.5, height * 0.6, 'Retry', this, () => {
			this.sound.play('click')
			this.scene.start('game', { level: data.currentLevel })
		})

		const nextLevelButton = new Button(width * 0.5, height * 0.7, 'Next Level', this, () => {
			this.sound.play('click')
			this.scene.start('game', { level: data.currentLevel + 1 })
		})
	}
}
