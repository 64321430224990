import { Direction } from '../consts/Direction'
import { WALL } from '../utils/SpriteUtils'

const Rectangle = Phaser.Geom.Rectangle
const GetRectangleIntersection = Phaser.Geom.Intersects.RectangleToRectangle
const LineToRectangle = Phaser.Geom.Intersects.LineToRectangle

const TILE_SIZE = 64
const HALF_TILE_SIZE = TILE_SIZE / 2


export default class TileManager {
	private game

	constructor(game) {
	  this.game = game
	}

	private getBoxAt(x: number, y: number)
	{
		for (const box of this.game.boxes) {
			if (box.x == x && box.y == y) {
				return box
			}
		}
	}

	private hasBoxAt(x: number, y: number)
	{
		const tile = this.getBoxAt(x, y)

		if (!tile) {
			return false
		}

		return tile !== null
	}

	private getMirrorAt(x: number, y: number)
	{
		for (const tile of this.game.mirrors) {
			if (tile.x == x && tile.y == y) {
				return tile
			}
		}
	}

	private hasMirrorAt(x: number, y: number)
	{
		const tile = this.getMirrorAt(x, y)

		if (!tile) {
			return false
		}

		return tile !== null
	}

	private getMoveableAt(x: number, y: number)
	{
		const box = this.getBoxAt(x, y)
		if (box) { return box }
		const mirror = this.getMirrorAt(x, y)
		if (mirror) { return mirror }
	}

	private getLaserAt(x: number, y: number)
	{
		const square = new Rectangle(x, y, TILE_SIZE, TILE_SIZE)
		for (const laser of this.game.lasers) {
			if (LineToRectangle(laser.geom, square)) {
				return laser
			}
		}
	}

	private hasLaserAt(x: number, y: number)
	{
		const laser = this.getLaserAt(x, y)
		return laser !== undefined
	}

	// private hasAdjacentLaser(x: number, y: number, direction: Direction) {
	// 	console.log("checking adjacent", x, y)
	// 	const leftSquare = new Rectangle(x-TILE_SIZE+1, y+1, TILE_SIZE-2, TILE_SIZE-2)
	// 	const rightSquare = new Rectangle(x+TILE_SIZE+1, y+1, TILE_SIZE-2, TILE_SIZE-2)

	// 	for (const laser of this.game.lasers) {
	// 		const laserBounds = laser.getBounds()
	// 		if (laser.plane == "horizontal") {
	// 			console.log("left: ", GetRectangleIntersection(laserBounds, leftSquare))
	// 			console.log("right: ", GetRectangleIntersection(laserBounds, rightSquare))
	// 			if (GetRectangleIntersection(laserBounds, leftSquare) || GetRectangleIntersection(laserBounds, rightSquare)) {
	// 				return true
	// 			}
	// 		}

	// 		// console.log(laserBounds, square)
	// 		// return GetRectangleIntersection(laserBounds, square)
	// 	}
	// 	return false

	// 	if (this.hasLaserAt(x-TILE_SIZE, y) || this.hasLaserAt(x+TILE_SIZE, y)) {
	// 		return true
	// 	}
	// 	return false
	// }

	private getPortalAt(x: number, y: number)
	{
		for (const portal of this.game.portals) {
			if (portal.x == x && portal.y == y) {
				return portal
			}
		}
	}

	private hasWallAt(x: number, y: number)
	{
		if (!this.game.layer)
		{
			return false
		}

		if (x < 0 || y < 0) return true
		if (x > (this.game.width-1)*TILE_SIZE || y > (this.game.height-1)*TILE_SIZE) return true

		const tile = this.game.layer.getTileAtWorldXY(x, y)
		if (!tile)
		{
			return false
		}

		return tile.index === WALL
	}

	private hasTurretAt(x: number, y: number)
	{
		for (const tile of this.game.turrets) {
			if (tile.x == x && tile.y == y) {
				return true
			}
		}
		return false
	}

	private canPlayerMoveTo(x: number, y: number)	{
		return !(this.hasWallAt(x, y) || this.hasTurretAt(x, y))
	}

	private canBoxMoveTo(x: number, y: number)	{
		return !(this.hasWallAt(x, y) || this.hasTurretAt(x, y) || this.hasBoxAt(x, y) || this.hasMirrorAt(x, y))
	}

	private hasExitAt(x: number, y: number, tileIndex: number)
	{
		const tile = this.game.layer.getTileAtWorldXY(x, y)
		return tile?.index === tileIndex
	}
}


export {
	HALF_TILE_SIZE,
	TILE_SIZE,
	TileManager
}
