import React, {useEffect, useState} from "react"
import {useParams} from 'react-router-dom'

import Phaser from 'phaser'

import Preloader from '../components/Game/scenes/Preloader'
import Game from '../components/Game/scenes/Game'
import LevelFinishedScene from '../components/Game/scenes/LevelFinishedScene'
import useAxios from 'axios-hooks'


export function LevelDetail() {
  let { levelId } = useParams()
  const [game, setGame] = useState(null)
  const [{ data: level, loading: levelLoading, error: levelError }] = useAxios({
    url: `levels/${levelId}`,
    headers: {
      "X-Fields": "{id,name,data,tiled_data,width,height,published_date,created_by{id,username},user_world{id,name},user_record{completed}}"
    }
  })
  const initGame = (levelData) => {
    if (game) {
      console.log("destroying")
      game.destroy(true)
    }
    const config = {
      type: Phaser.AUTO,
      parent: 'phaser',
      dom: {
        createContainer: true
      },
      width: levelData.width * 64,
      height: levelData.height * 64,
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 200 }
        }
      },
      scale: {
          mode: Phaser.Scale.FIT,
          autoCenter: Phaser.Scale.NO_CENTER,
          min: {"width": 400, "height": 400},
          max: {"width": 600, "height": 800},
      },
      scene: [Preloader, Game, LevelFinishedScene]
    }
    let newGame = new Phaser.Game(config)
    newGame.levelData = levelData
    setGame(newGame)
  }

  useEffect(() => {
    if (!levelLoading && !levelError && level) initGame(level)
  }, [level])

  return (
    <section className="section">
      <div className="container">
        { level ? <h1 className="title">{level.name}</h1> : null}

        <div id="phaser"></div>
        { levelLoading ? (
          <p>Loading...</p>
        ) : null}

        { levelError ? (
          <p>Error</p>
        ) : null}
      </div>
    </section>
  )
}


export default LevelDetail
