import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home'
import LogIn from './pages/LogIn'
import Register from './pages/Register'
import UserConfirmation from './pages/UserConfirmation'
import LevelCreate from './pages/LevelCreate'
import LevelDetail from './pages/LevelDetail'
import UserDetail from './pages/UserDetail'

import {Navigation} from "./components/Navigation"

import {UserContextProvider} from "./auth/UserContextProvider"

import {Amplify} from "aws-amplify"
import { configure } from 'axios-hooks'
import apiInstance from './api/ApiInstance'

configure({ axios: apiInstance })

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_USER_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  }
})

function App() {
  return (
    <BrowserRouter>
      <UserContextProvider>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/user/confirmation" element={<UserConfirmation />} />
          <Route path="/users/:userId" element={<UserDetail />} />
          <Route path="/levels/create" element={<LevelCreate />} />
          <Route path="/levels/:levelId" element={<LevelDetail />} />
          <Route path="/levels/:levelId/edit" element={<LevelCreate />} />
        </Routes>
      </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;
