import React from "react"


export function LevelList(props) {
  const getCompletionModifier = (levelRecord) => {
    if (levelRecord) {
      if (levelRecord.completed) return "completed"
      return "started"
    }
    return "incomplete"
  }

  return (
    <ul className="level-list">
        {props.levels.map((level) => {
          let createdBy = props.createdBy ? props.createdBy : level.created_by
          return (
            <li key={level.id} className={"level-list-item has-icons-right" + (level.published_date ? " level-list-item--published" : " level-list-item--unpublished")}>

                {
                  createdBy.id == props.currentUserId ? (
                    <div className="level-list-item__name">
                      <div className="icon-text">
                        <a href={"/levels/" + level.id}>{ level.name }</a>
                        <span className="icon has-text-info is-lare">
                          <a className="button is-small is-ghost" href={"/levels/" + level.id + "/edit"}>
                            <i className="fas fa-pen-to-square"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="level-list-item__name" >
                      <a href={"/levels/" + level.id}>{ level.name }</a>
                    </div>
                  )
                }

                <a className="level-list-item__author" href={"/users/" + createdBy.id}>{ createdBy.username }</a>
                <span className={"icon is-large level-list-item__completion level-list-item__completion--" + getCompletionModifier(level.user_record)}>
                  <i className="fa-sharp fa-solid fa-check"></i>
                </span>

            </li>
          )
        })}
    </ul>
  )
}

export default LevelList
