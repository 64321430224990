import axios from "axios"
import { Auth } from 'aws-amplify'


const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json"
  },
})


apiInstance.interceptors.request.use(async (config) => {
    try {
        const session = await Auth.currentSession()
        const jwtToken = session.idToken.jwtToken
        config.headers.Authorization = `Bearer ${jwtToken}`
    } catch {
        console.log("Unable to authenticate user")
    }
    return config
})


export default apiInstance
