enum Direction {
  LEFT,
  RIGHT,
  UP,
  DOWN
}

export {
	Direction
}

export type Move = {
  direction: Direction
  withBox: boolean
}
