import React, {useContext, useEffect, useState} from "react"
import { useNavigate } from "react-router-dom"
import { Auth } from 'aws-amplify'
import {UserContext} from "../auth/UserContextProvider"

import {UNCONFIRMED_USER_KEY} from '../utils/Constants'


export function UserConfirmation() {
  const [code, setCode] = useState('')
  const [cognitoError, setCognitoError] = useState()
  const [resendSent, setResendSent] = useState(false)
  const {setUser} = useContext(UserContext)
  const username = localStorage.getItem(UNCONFIRMED_USER_KEY)
  let navigate = useNavigate()

  const onSubmit = (e) => {
    e.preventDefault()

    Auth.confirmSignUp(username, code).then(user => {
        localStorage.removeItem(UNCONFIRMED_USER_KEY)
        console.log(user)
        setUser(user)
        navigate("/")
    }).catch(error => {
      console.log(error)
      setCognitoError("Unable to verify email address")
    })

  }

  const resendEmail = () => {
    Auth.resendSignUp(username).then(user => {
      setResendSent(true)
      setTimeout(() => {
        setResendSent(false)
      }, 5000)
    }).catch(error => {
      console.log(error)
    })

  }

  useEffect(() => {
    if (!username) {
      console.log("redirecting...", username)
      return navigate("/login")
    } else {
      console.log("not redirecting...", username)
    }
  }, [])


  return (
    <section className="section">
      <div className="container">
        <h1 className="title">Confirm e-mail address</h1>
        <p>Please check your email for a confirmation code.</p>

        {resendSent ?
          <div className="notification is-success">
            <button className="delete"></button>
            Confirmation email resent
          </div>
        : (
          <span className="button is-light" onClick={() => {resendEmail()}}>Resend confirmation email</span>
        )}

        <form method="post" onSubmit={onSubmit}>

            <div className="field">
              <label className="label" htmlFor="username">Username</label>
              <p className="control has-icons-left has-icons-right">
                <span>{username}</span>

              </p>
            </div>

            <div className="field">
              <label className="label" htmlFor="code">Code</label>
              <p className="control has-icons-left">
                <input id="code" className="input" name="code" type="text" onChange={(e) => setCode(e.target.value)} />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>

            { cognitoError ? (
                <p className="help is-danger">{ cognitoError }</p>
            ) : null }

            <div className="field">
              <div className="control">
                <button className="button is-link">Confirm Code</button>
              </div>
            </div>
        </form>
      </div>
    </section>
  )
}


export default UserConfirmation
