import { Direction } from '../consts/Direction'

import { TILE_SIZE } from '../utils/TileUtils'


const offsetForDirection = (direction: Direction, steps = 1) => {
    const multipler = steps
    switch (direction)
    {
        case Direction.LEFT:
            return {
                x: (TILE_SIZE * -multipler),
                y: 0
            }

        case Direction.RIGHT:
            return {
                x: (TILE_SIZE * multipler),
                y: 0
            }

        case Direction.UP:
            return {
                x: 0,
                y: (TILE_SIZE * -multipler)
            }

        case Direction.DOWN:
            return {
                x: 0,
                y: (TILE_SIZE * multipler)
            }

        default:
            return { x: 0, y: 0 }
    }
}

const oppositeDirection = (direction: Direction) => {
    switch (direction) {
        case Direction.LEFT:
            return Direction.RIGHT
        case Direction.RIGHT:
            return Direction.LEFT
        case Direction.UP:
            return Direction.DOWN
        case Direction.DOWN:
            return Direction.UP
    }
}


export {
    offsetForDirection,
    oppositeDirection,
}
